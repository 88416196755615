import { Col, Row, Section } from 'components/grid'
import SubNav from 'components/SubNav'
import Layout from 'components/Layout'
import OverlayVideo from 'components/overlay-video'
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Button } from '@arckit/ui'
import { graphql, Link, useStaticQuery } from 'gatsby'
import SectionTitle from 'components/global/SectionTitle'
import LargeForm from 'components/forms/LargeForm'
import { H1 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import Title from 'components/typography/Title'

const subNavItems = [
	{ name: 'Overview', link: 'overview' },
	{ name: 'LOS', link: 'literacy-operating-system' },
	// { name: 'ARC Core', link: 'arc-core' },
	// { name: 'ARC University', link: 'arc-university' },
	// { name: '100 Book Challenge', link: '100-book-challenge' },
	// { name: 'IRLA/ENIL', link: 'irla-enil' },
	// { name: 'Summer Semester', link: 'summer' },
	{ name: 'Leadership Learning', link: 'leadership-learning-series' }
]

export default function ProfessionalLearningPage() {
	const { plpg } = useStaticQuery<IQuery>(query)
	return (
		<Layout pageName="Professional Learning">
			<SubNav items={subNavItems} />
			<Section flatGray>
				<Row middle className="pb-12 pt-12 md:pb-16 md:pt-0">
					<Col width="w-full md:w-1/2">
						<div>
							<H1 className="mb-3">Professional Learning</H1>
							<P className="mb-3">
								ARC provides the system in which adults get better every day. Professional learning is embedded in the tools teachers and leaders are using as they
								analyze data, plan and adjust instruction, and address systemic barriers to equity and excellence.
							</P>
							<P>
								As part of a comprehensive, continuous approach to improvement, the ARC team works side by side with teachers and leaders to establish the
								conditions for success, guide implementation, grow expertise, and change outcomes for all students.
							</P>
						</div>
					</Col>
					<Col width="w-full md:w-1/2" gutter="none" className="relative hidden md:block">
						<StaticImage src="../../static/images/professional-learning/hero.png" alt="professional learning" />
						<div className="absolute right-10 bottom-10">
							<a href="https://plpartnerguide.org/partner/american-reading-company/" target="_blank" rel="noopener noreferrer">
								<GatsbyImage image={plpg.gatsbyImageData} alt="plpg badge" className="rounded-full bg-white" />
							</a>
						</div>
					</Col>
				</Row>
			</Section>
			<Section id="overview" scrollMarginTop="75px">
				{/* <Row middle>
					<Col width="w-full md:w-1/2">
						<p>
							All ARC professional development is structured as Professional Learning Cycles around a gradual–release model, starting out with a workshop, leadership
							learning sessions, moving into collaborative planning/grade group meetings, then into "fishbowl" demonstration lessons, and finally into one–on–one
							support, as needed.
						</p>
						<p>
							This model is implemented during the academic school day and year. It transforms school culture for sustainable student achievement, and positions and
							supports the principal as the head learner and instructional leader.
						</p>
					</Col>
					<Col width="w-full md:w-1/2">
						<div className="mx-auto w-full max-w-xl rounded border bg-slate-100 p-10 shadow-lg">
							<p className="font-semibold text-slate-600">
								American Reading Company provides district and school leaders with the tools and support they need as they:
							</p>
							<ul className="list-checkmark ml-10 text-lg text-slate-600">
								<li className="mb-2">Establish academic rigor and differentiated support</li>
								<li className="mb-2">Organize all stakeholders around critical evidence of student learning</li>
								<li>Manage a process of systemic change to improve instructional effectiveness.</li>
							</ul>
						</div>
					</Col>
				</Row> */}
				<Row center className="mt-10">
					<Col width="w-full md:w-3/4">
						<OverlayVideo title="The Impact of ARC Professional Learning" />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-40">
				<SectionTitle title="How We Provide Professional Development" text="ARC PD may occur onsite or online in real time or asynchronously." />
				<Row center>
					<Col width="w-full md:w-1/2" className="mb-6">
						<div className="h-full border p-6 shadow">
							<Title className="mb-2 text-ab-100">Workshops</Title>
							<P size="md" shade="darker">
								ARC's workshop structure supports a transformational literacy design. Teachers work to establish a culture of engaged readers and writers and to
								increase rigor through the use of high-quality, grade level texts.
							</P>
						</div>
					</Col>
					<Col width="w-full md:w-1/2" className="mb-6">
						<div className="h-full border p-6 shadow">
							<Title className="mb-2 text-ab-100">PLCs</Title>
							<P size="md" shade="darker">
								ARC Coaches work collaboratively with teachers in PLCs as they learn to effectively implement ARC Core. While engaged in 1:1 coaching, teachers
								develop a high capacity to analyze student work to plan effective lessons and to deliver strong, grade level core instruction and differentiated
								instruction. 
							</P>
						</div>
					</Col>
					<Col width="w-full md:w-1/2" className="mb-6">
						<div className="h-full border p-6 shadow">
							<Title className="mb-2 text-ab-100">Coaching</Title>
							<P size="md" shade="darker">
								Certified ARC Executive Coaches engage with teachers and leaders to co-plan, co-teach, demonstrate, and observe lessons. Feedback and cycles of
								continuous improvement are used to further instructional practice, identify unique teacher and classroom needs, and to ensure PL sessions remain
								relevant across the system and implementation. 
							</P>
						</div>
					</Col>
					<Col width="w-full md:w-1/2" className="mb-6">
						<div className="h-full border p-6 shadow">
							<Title className="mb-2 text-ab-100">Consultations</Title>
							<P size="md" shade="darker">
								ARC's Professional Learning is flexible and adaptable to meet the needs of schools and districts. ARC provides targeted support to improve
								implementation of its HQIM, ARC Core, through consultative partnership. This partnership may include iterative cycles of continuous improvement with
								leaders in its Leadership Learning Series, and/or custom PD.
							</P>
						</div>
					</Col>
					<Col width="w-full md:w-1/2" className="mb-6">
						<div className="h-full border p-6 shadow">
							<Title className="mb-2 text-ab-100">Learning Lab</Title>
							<P shade="darker" size="md" className="mb-6">
								ARC Executive Coaches, in collaboration with district leadership, develop literacy leaders to run site-based coaching in their building. Principals
								and Literacy Coaches from across the district work together with targeted support from the ARC Executive Coach in the lab school to create a working
								model with demonstrated impact. Participants collaboratively determine a shared vision of success and are responsible for coaching their own
								schools.
							</P>
							<img src="/images/literacy-operating-system/learning-lab-cropped.svg" alt="learning lab" />
						</div>
					</Col>
				</Row>
			</Section>
			<Section id="literacy-operating-system" margin="mt-20" scrollMarginTop="75px">
				<Row>
					<Col className="mb-4">
						<img src="/images/logos/los-logo.svg" alt="los logo" className="mx-auto max-w-md" />
					</Col>
				</Row>
				<Row middle>
					<Col width="w-full md:w-1/2" className="mb-6 md:mb-0">
						<P className="mb-3">
							American Reading Company offers partners the opportunity to implement a Literacy Operating System that puts high-quality curriculum, leadership
							learning, and a sustained commitment to academic success for every student at its core.
						</P>
						<Link to="/literacy-operating-system">
							<Button size="lg">Learn More</Button>
						</Link>
					</Col>
					<Col width="w-full md:w-1/2">
						<div className="rounded border bg-slate-100 p-10 shadow-lg">
							<Title className="mb-3 text-lg">
								Effective, shoulder-to-shoulder Professional Learning is a key component of ARC's Literacy Operating System (LOS)
							</Title>
							<ul className="list-checkmark ml-10 text-lg text-slate-600">
								<li className="mb-3">Data-Driven Performance Management</li>
								<li className="mb-3">Digital Learning Platform</li>
								<li className="mb-3">Teacher Expertise</li>
								<li>Leadership Development</li>
							</ul>
						</div>
					</Col>
				</Row>
			</Section>
			{/* <Section id="arc-core" margin="mt-20" scrollMarginTop="75px">
				<Row>
					<Col>
						<img src="/images/logos/arc-core-logo.svg" alt="arc core logo" className="mx-auto max-w-md" />
						<span className="my-6 block text-center text-3xl">Reading, Writing, and Research Across the Content Areas</span>
						<div>
							<p>
								ARC Coaches work with teachers in their own classrooms as they learn to create the conditions necessary to establish an authentically engaged
								literary culture. Professional development for ARC Core<sup>®</sup> is implemented during the academic school day, structured around gradual
								release, highly adaptable and flexible, to meet the needs of the district and/or school. ARC Coaches develop Professional Learning communities as
								Continuous–Improvement Teams.
							</p>
							<p>
								Professional development for ARC Core helps teachers understand the specific elements of project–based learning so they can effectively facilitate
								their students’ learning. Teachers receive further support in the form of teacher coaching visits and detailed ARC Core workshops. All of this is
								focused on showing teachers how to make their students successful readers, writers, and consumers of nonfiction texts.
							</p>
						</div>
					</Col>
				</Row>
			</Section> */}
			{/* <Section id="arc-university" margin="mt-20" scrollMarginTop="75px">
				<Row>
					<Col>
						<img src="/images/logos/arc-university-logo-rectangle.svg" alt="arc university logo" className="mx-auto max-w-md" />
						<span className="my-6 block text-center text-3xl">Asynchronous Professional Learning from ARC</span>
						<div>
							<div className="mx-auto w-full md:w-3/4">
								<OverlayVideo title="ARC University" />
								<p className="mb-0 mt-3 text-center">
									ARC University, our asynchronous online Professional Learning platform, allows educators to connect to the knowledge, experience, and proven
									strategies of our Professional Learning team on demand.
								</p>
							</div>
						</div>
					</Col>
				</Row>
			</Section> */}
			{/* <Section id="100-book-challenge" margin="mt-20" scrollMarginTop="75px">
				<Row>
					<Col>
						<img src="/images/logos/100-bc-logo.svg" alt="100 book challenge logo" className="mx-auto max-w-sm" />
						<span className="my-6 mt-8 block text-center text-3xl">Get Every Student to Proficient in Reading</span>
					</Col>
				</Row>
				<Row middle>
					<Col width="w-full md:w-1/2">
						<p>
							100 Book Challenge<sup>®</sup> begins with a comprehensive, interactive, hands–on introduction to the program. Where possible, we include in–class
							demonstrations with your students. Support continues with several types of teacher coaching visits, each focusing on a different important aspect of
							program implementation.
						</p>
						<p>
							American Reading Company Executive Coaches support district and school leaders as they learn to organize all stakeholders around critical evidence of
							student learning. ARC Coaches work with teachers in their own classrooms as they learn to integrate state standards into their daily practice.
						</p>
					</Col>
					<Col width="w-full md:w-1/2">
						<div className="rounded border bg-slate-100 p-10 shadow-lg">
							<p className="font-semibold text-slate-600">All ARC Professional Development is structured around improvement cycles and the gradual–release model.</p>
							<ul className="list-checkmark ml-10 text-lg text-slate-600">
								<li className="mb-2">One–Day On–Site Workshop</li>
								<li className="mb-2">Grade Group Meetings</li>
								<li className="mb-2">Fishbowl Demonstration Lessons</li>
								<li className="mb-2">one–on–one Support as Needed</li>
								<li>Status Checks</li>
							</ul>
						</div>
					</Col>
				</Row>
			</Section> */}
			<Section margin="mt-40" id="leadership-learning-series" scrollMarginTop="75px">
				<Row>
					<Col width="w-full md:w-1/2" className="mx-auto mb-6">
						<img src="/images/logos/lls.svg" alt="lls logo" />
					</Col>
				</Row>
				<Row className="mb-10">
					<Col width="w-full md:w-3/4" className="mx-auto">
						<OverlayVideo title="Leadership Learning Series Promo" />
					</Col>
				</Row>
			</Section>
			<Section>
				<SectionTitle
					title="Get Better at Getting Better"
					text="Leadership coaches use improvement science to build consensus, develop infrastructure, and design a unique five-year plan to move from implementation to innovation."
				/>
				<Row>
					<Col className="mb-8">
						<div className="flex items-center justify-between">
							<div className="flex items-center gap-6">
								<div>
									<span className="block text-center font-bold text-ab-100">Year</span>
									<span className="block text-center text-8xl font-bold text-ab-100">1</span>
								</div>
								<img src="/images/professional-learning/implement.jpg" alt="focus book cover" className="h-64" />
								<div className="flex flex-col">
									<Title className="text-ab-100">Implement</Title>
									<P size="md" shade="darker">
										Launching the Leadership Learning Series
									</P>
									<span className="font-semibold">Establish a high-quality implementation of ARC Core® across all classrooms.</span>
								</div>
							</div>
						</div>
					</Col>
					<Col className="mb-8">
						<div className="flex items-center justify-between">
							<div className="flex items-center gap-6">
								<div>
									<span className="block text-center font-bold text-ab-100">Year</span>
									<span className="block text-center text-8xl font-bold text-ab-100">2</span>
								</div>
								<img src="/images/professional-learning/refine.jpg" alt="focus book cover" className="h-64" />
								<div className="flex flex-col">
									<Title className="text-ab-100">Refine</Title>
									<P size="md" shade="darker">
										Continually examining the system and understanding the changes needed to make the system work
									</P>
									<span className="font-semibold">Use the science of improvement to refine implementation.</span>
								</div>
							</div>
						</div>
					</Col>
					<Col className="mb-8">
						<div className="flex items-center justify-between">
							<div className="flex items-center gap-6">
								<div>
									<span className="block text-center font-bold text-ab-100">Year</span>
									<span className="block text-center text-8xl font-bold text-ab-100">3</span>
								</div>
								<img src="/images/professional-learning/scale.jpg" alt="focus book cover" className="h-64" />
								<div className="flex flex-col">
									<Title className="text-ab-100">Scale</Title>
									<P size="md" shade="darker">
										Moving from small- to large-scale implementation
									</P>
									<span className="font-semibold">Scale refinements system-wide to ensure success for all students.</span>
								</div>
							</div>
						</div>
					</Col>
					<Col className="mb-8">
						<div className="flex items-center justify-between">
							<div className="flex items-center gap-6">
								<div>
									<span className="block text-center font-bold text-ab-100">Year</span>
									<span className="block text-center text-8xl font-bold text-ab-100">4</span>
								</div>
								<img src="/images/professional-learning/nurture.jpg" alt="focus book cover" className="h-64" />
								<div className="flex flex-col">
									<Title className="text-ab-100">Nurture</Title>
									<P size="md" shade="darker">
										Living the improvement
									</P>
									<span className="font-semibold">Nurture a culture of collaborative innovation that uses data to drive dramatic results.</span>
								</div>
							</div>
						</div>
					</Col>
					<Col className="mb-8">
						<div className="flex items-center justify-between">
							<div className="flex items-center gap-6">
								<div className="flex flex-col">
									<span className="block text-center font-bold text-ab-100">Year</span>
									<span className="block text-center text-8xl font-bold text-ab-100">5</span>
								</div>
								<img src="/images/professional-learning/innovate.jpg" alt="focus book cover" className="h-64" />
								<div className="flex flex-col">
									<Title className="text-ab-100">Innovate</Title>
									<P size="md" shade="darker">
										Building an equitable, transformative system
									</P>
									<span className="font-semibold">Sustain an innovative, high-performing system of continuous improvement.</span>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Section>

			{/* <Row>
					<Col className="mb-3">
						<div className="rounded border bg-slate-100 p-10 shadow-lg">
							<span className="text-3xl font-bold text-ab-100">Phase 1: Improvement Cycle Preparation</span>
							<span className="block text-xl font-semibold text-slate-600">Time: 1 Day</span>
							<div className="mt-6">
								<p className="font-semibold text-slate-600">
									The vision of a learning organization can live in practice only if it can be shared and understood by everyone.
								</p>
								<ul className="list-checkmark ml-10 text-lg text-slate-600">
									<li className="mb-3">Answer key improvement questions focused on the specific changes needed for focused and manageable improvements.</li>
									<li className="mb-3">
										Create a shared understanding of the ARC solution that will be implemented and plan to capture and engage the hearts and minds of
										stakeholders whose work will be impacted.
									</li>
									<li>Create the conditions necessary for systems improvement through engagement, evidence collection, and continuous-improvement cycles.</li>
								</ul>
							</div>
						</div>
					</Col>
					<div className="flex w-full justify-center">
						<div className="h-20 w-[2px] border-l-2"></div>
					</div>
					<Col className="mt-3 mb-3">
						<div className="rounded border bg-slate-100 p-10 shadow-lg">
							<span className="text-3xl font-bold text-ab-100">Phase 2: Improvement Cycle Implementation</span>
							<span className="block text-xl font-semibold text-slate-600">Time: 8 Days</span>
							<div className="mt-6">
								<p className="font-semibold text-slate-600">
									Stakeholders will continually examine the system that is producing the current results and understand the changes needed to make the system work
									better for all students.
								</p>
								<ul className="list-checkmark ml-10 text-lg text-slate-600">
									<li className="mb-3">
										Understand how multiple cycles of the Plan, Do, Study, Act method can keep an organization focused on continuous-improvement.
									</li>
									<li className="mb-3">Engage in multiple cycles of improvement focused on scaling positive results and providing needed supports.</li>
									<li>Create networks hub where improvement evidence can be shared, learned from, and scaled across the organization.</li>
								</ul>
							</div>
						</div>
					</Col>
					<div className="flex w-full justify-center">
						<div className="h-20 w-[2px] border-l-2"></div>
					</div>
					<Col className="mt-3">
						<div className="rounded border bg-slate-100 p-10 shadow-lg">
							<span className="text-3xl font-bold text-ab-100">Phase 3: Project Analysis</span>
							<span className="block text-xl font-semibold text-slate-600">Time: 1 Day</span>
							<div className="mt-6">
								<p className="font-semibold text-slate-600">
									Continuous-improvement becomes a culture as an organization learns how to study itself to move from small–scale to large–scale implementation.
								</p>
								<ul className="list-checkmark ml-10 text-lg text-slate-600">
									<li className="mb-3">Engage in a comprehensive study of the implementation using improvement–cycle evidence.</li>
									<li className="mb-3">Combine both process and outcome data to identify new needs, goals, and action steps.</li>
									<li>Prioritize organizational changes to produce and scale meaningful improvements.</li>
								</ul>
							</div>
						</div>
					</Col>
				</Row> */}
			<Section margin="mt-10" className="bg-ab-100">
				<Row center className="relative z-auto">
					<div className="mt-14 max-w-3xl px-4">
						<div className="relative">
							<svg xmlns="http://www.w3.org/2000/svg" className="absolute -top-10 -left-20 hidden h-20 md:block" viewBox="0 0 130.51 115">
								<path
									d="m43.65 0 16.26 19.53Q41 38.43 36 45.76a62.39 62.39 0 0 0-7.91 17.88 19.6 19.6 0 0 1 5.49-3.23 16.16 16.16 0 0 1 5.63-1q8.27 0 14.47 7.56a27.88 27.88 0 0 1 6.2 18.27q0 12.6-7.91 21.19a25.31 25.31 0 0 1-19.3 8.57 28.34 28.34 0 0 1-16.19-5.28A35.26 35.26 0 0 1 4.36 95.23 49.51 49.51 0 0 1 0 73.88q0-20 12.05-39.07A143.1 143.1 0 0 1 43.65 0m70.49 0 16.22 19.53q-18.78 18.9-23.76 26.23a61.23 61.23 0 0 0-8 17.88 19.41 19.41 0 0 1 5.48-3.23 16.1 16.1 0 0 1 5.62-1q8.25 0 14.51 7.56a27.72 27.72 0 0 1 6.26 18.27q0 12.6-8 21.19a25.44 25.44 0 0 1-19.29 8.57A28.29 28.29 0 0 1 87 109.72a35.19 35.19 0 0 1-12.06-14.49 49.52 49.52 0 0 1-4.33-21.35q0-20 12-39.07A142.78 142.78 0 0 1 114.14 0"
									fill="#0063A2"
								/>
							</svg>
							<p className="relative z-20 text-center text-2xl italic text-white">
								High-quality materials and teaching strategies, authentic texts, and student choice are some of the reasons why we have seen reading and writing
								improve for our students. The ongoing and engaging professional development for our teachers and administrators has made the rollout seamless and
								successful. We are on a great path to success with our partnership and I am excited to see how much more we learn and grow!
							</p>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="absolute -bottom-10 -right-28 hidden h-20 rotate-180 transform md:block lg:bottom-0"
								viewBox="0 0 130.51 115"
							>
								<path
									d="m43.65 0 16.26 19.53Q41 38.43 36 45.76a62.39 62.39 0 0 0-7.91 17.88 19.6 19.6 0 0 1 5.49-3.23 16.16 16.16 0 0 1 5.63-1q8.27 0 14.47 7.56a27.88 27.88 0 0 1 6.2 18.27q0 12.6-7.91 21.19a25.31 25.31 0 0 1-19.3 8.57 28.34 28.34 0 0 1-16.19-5.28A35.26 35.26 0 0 1 4.36 95.23 49.51 49.51 0 0 1 0 73.88q0-20 12.05-39.07A143.1 143.1 0 0 1 43.65 0m70.49 0 16.22 19.53q-18.78 18.9-23.76 26.23a61.23 61.23 0 0 0-8 17.88 19.41 19.41 0 0 1 5.48-3.23 16.1 16.1 0 0 1 5.62-1q8.25 0 14.51 7.56a27.72 27.72 0 0 1 6.26 18.27q0 12.6-8 21.19a25.44 25.44 0 0 1-19.29 8.57A28.29 28.29 0 0 1 87 109.72a35.19 35.19 0 0 1-12.06-14.49 49.52 49.52 0 0 1-4.33-21.35q0-20 12-39.07A142.78 142.78 0 0 1 114.14 0"
									fill="#0063A2"
								/>
							</svg>
						</div>
						<div className="mx-auto my-6 w-32 border-b-4 border-b-ab-100"></div>
						<p className="text-center text-white">– Jenny Nauman, Assistant Superintendent</p>
					</div>
				</Row>
			</Section>

			<Section margin="mt-40">
				<Row className="justify-center pb-6">
					<Col width="w-full md:w-5/6 lg:w-3/4 xl:w-1/2">
						<LargeForm pageName="Professional Learning" />
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}

interface IQuery {
	plpg: { gatsbyImageData: IGatsbyImageData }
}

const query = graphql`
	query {
		plpg: contentfulAsset(title: { eq: "PLPG Badge" }) {
			gatsbyImageData(height: 210)
		}
	}
`
